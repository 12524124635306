.container {
  @extend %container;
}

#main {
  position: relative;
}

.no-sidebars {
  #maincontent {
    @extend %container;
  }
}

.layout-3col__left-content {
  @extend %container;
  display: flex;

  @include xssm {
    display: block;
  }

  #maincontent {
    flex: 0 0 65%;
  }

  aside {
    &.layout-3col__right-sidebar {
      flex: 0 0 25%;

      .page-node-26 & {
        flex: 0 0 35%;
      }
    }
  }

  .node {
    @include rem(padding, 20px 100px);
    @include rem(margin-top, 50px);

    @include xssm {
      padding: 0;
      max-width: 90%;
      margin: 20px auto;
    }
  }
}

.node-type-projet {

  // .fixed,
  // .fixed.abso {
  //   position: sticky;
  // }
  //
  // .abso {
  //   position: absolute;
  //   right: 0;
  // }
  #main {
    @include rem(padding-top, 50px);
  }

  .layout-3col__left-content {
    align-items: flex-start;

    #maincontent {
      flex: 0 0 55%;
    }

    aside {
      &.layout-3col__right-sidebar {
        flex: 0 0 45%;
      }
    }

    .node {
      @include rem(padding, 0 70px 0 0);
    }

    .view-projet {
      .view-content {
        ul {
          li {
            list-style: none;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .btn-outline {
    @include rem(padding, 3px 15px);
    @include rem(margin-bottom, 100px);
    border-color: $primary-color;
    color: $primary-color;

    &:hover,
    &:focus {
      color: $white;
      background: $primary-color;
    }
  }
  h3 {
    @include rem(font-size, 24px);
  }

  h1 {
    @include rem(font-size, 36px);
    @include rem(padding-bottom, 15px);
    display: inline-block;
    background-image: linear-gradient(to right, $gray-darkest 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;

    &:after {
      display: none;
    }
  }

  .field-name-field-service {
    @include rem(margin-bottom, 50px);
  }

  .field-name-body {
    @include rem(font-size, 15px);
  }
}
