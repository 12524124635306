/* -------------------------------
 * REM MIXIN
 * ------------------------------*/

@function strip-units(
	$val
) {

	@return ($val / ($val * 0 + 1));
}

$default-root-font-size: $global-font-size !default;

@mixin rem(
	$property,
	$values,
	$root: $default-root-font-size
) {

	$px:	();
	$rem:	();

	@if not unitless($root) {
	$root: strip-units($root);
	}

	@each $value in $values {

	@if $value == 0 or type-of($value) != "number" {
	$px:	append($px, $value);
	$rem: append($rem, $value);
	}
	@else {
	$unit: unit($value);
	$val:	strip-units($value);

	@if $unit == "px" {
	$px:	append($px, $value);

	@if $val == 1 {
		$rem: append($rem, $value);
	}
	@else {
		$rem: append($rem, ($val / $root + rem));
	}
	}
	@else if $unit == "rem" {
	$px:	append($px, ($val * $root + px));
	$rem: append($rem, $value);
	}
	@else {
	$px:	append($px, $value);
	$rem: append($rem, $value);
	}
	}
	}

	@if $px == $rem {
	#{$property}: $px;
	}
	@else {

	@if $enable-legacy-support-for-ie8 {
	#{$property}: $px; // Fallback for IE8.
	}
	#{$property}: $rem;
	}
}


/* -----------------------------------
 * Font Awesome
 * ----------------------------------*/
@mixin font-awesome($icon) {
	content: $icon;
	font-family: FontAwesome;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin custom-font($icon) {
	content: $icon;
	display: inline-block;
	font-family: 'icofont';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-transform: none;
}

/* -----------------------------------
 * Color
 * ----------------------------------*/

$default-color: $primary-color !default;

@mixin color(
	$color: $default-color,
	$alpha: 1
) {

	@if $enable-legacy-support-for-ie8 and $alpha != 1 and ($color != transparent or $color != inherit) {
	color: $color; // IE8- needs a solid background color.
	}
	@if $alpha == 1 or ($color == transparent or $color == inherit) {
	color: $color;
	}
	@else {
	color: rgba($color, $alpha);
	}
}

@function color(
	$color: $default-color,
	$alpha: 1
) {

	@if $alpha == 1 or ($color == transparent or $color == inherit) {
	@return $color;
	}
	@else {
	@return rgba($color, $alpha);
	}
}

/* -----------------------------------
 * Gradient
 * ----------------------------------*/

/*
	Exemple d'utilisation

	.selector-1 {
	// $default-direction: 180deg
	@include background-gradient($brand-raspberry, $brand-candy);
	}

	.selector-2 {
	@include background-gradient(to right, (color($brand-candy, .53) 10%, color($brand-candy, .96) 100%));
	}

	.selector-3 {
	@include background-gradient(48deg, (color($brand-candy, .1) 0%, color($brand-candy, .5) 50%, $brand-candy 100%));
	}
*/
$default-direction: 180deg !default;

@function is-direction(
	$value
) {

	$is-keyword: index(
	(
	to top,
	to top right,
	to right top,
	to right,
	to bottom right,
	to right bottom,
	to bottom,
	to bottom left,
	to left bottom,
	to left,
	to left top,
	to top left
	),
	$value
	);

	$is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

	@return $is-keyword or $is-angle;
}


@function legacy-direction(
	$value
) {

	@if is-direction($value) == false {
	@error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
	}

	$conversion-map: (
	to top		: bottom,
	to top right	: bottom left,
	to right top	: left bottom,
	to right	: left,
	to bottom right : top left,
	to right bottom : left top,
	to bottom	: top,
	to bottom left	: top right,
	to left bottom	: right top,
	to left	 : right,
	to left top	: right bottom,
	to top left	: bottom right
	);

	@if map-has-key($conversion-map, $value) {
	@return map-get($conversion-map, $value);
	}

	@return 90deg - $value;
}


@mixin background-gradient(
	$direction,
	$color-stops
) {

	$start-color: nth(nth($color-stops, 1), 1);
	$end-color: nth(nth($color-stops, length($color-stops)), 1);


	@if is-direction($direction) == false {
	$color-stops: $direction, $color-stops;
	$direction: $default-direction;
	}


	background: $start-color;
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);

	// IE8 support
	@if $enable-legacy-support-for-ie8 {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}00', endColorstr='#{$end-color}00',GradientType=0);
	}
}


/* -----------------------
 * Mediaqueries
 * ---------------------*/
 // ##### CONFIG
 //<= 320
 $xxxs: '(max-width: ' + $screen-xxs + ')';
 $xxxs-d: '(max-device-width: ' + $screen-xxs + ')';
 $xxxs-d-portrait: '(max-device-width: ' + $screen-xxs + ') and (orientation: portrait)';

 // <= 480
 $xxs: '(max-width: ' + $screen-xs + ')';
 $xxs-d: '(min-device-width :	' + $screen-xxs + ' ) and (max-device-width: ' + $screen-xs - 1 + ')';
 $xxs-d-portrait: '(max-device-width: ' + $screen-xs + ') and (orientation: portrait)';


 $xs: '(max-width: ' + $screen-xs-max + ')';	// <= 767
 $xs-d: '(max-device-width: ' + $screen-xs-max + ')'; // <= 767
 $xs-d-portrait: '(max-device-width: ' + $screen-xs-max + ') and (orientation: portrait)';	// <= 767
 $xs-d-landscape: '(max-device-width: ' + $screen-xs-max + ') and (orientation: landscape)';	// <= 767

 $sm: '(min-width: ' + $screen-sm-min + ') and (max-width: ' + $screen-sm-max + ')';	// 768 -> 991
 $sm-d: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' + $screen-sm-max + ')';	// 768 -> 991
 $sm-d-portrait: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' + $screen-sm-max + ') and (orientation: portrait)';	// 768 -> 991
 $sm-d-landscape: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' + $screen-sm-max + ') and (orientation: landscape)';	// 768 -> 991

 $md: '(min-width: ' + $screen-md-min + ') and (max-width: ' + $screen-md-max + ')';	// 992 -> 1199
 $md-d: '(min-device-width: ' + $screen-md-min + ') and (max-device-width: ' + $screen-md-max ')';	// 992 -> 1199
 $md-d-portrait: '(min-device-width: ' + $screen-md-min + ') and (max-device-width: ' + $screen-md-max ') and (orientation: portrait)';	// 992 -> 1199
 $md-d-landscape: '(min-device-width: ' + $screen-md-min + ') and (max-device-width: ' + $screen-md-max ') and (orientation: landscape)';	// 992 -> 1199

 $lg: '(min-width: ' + $screen-lg-min + ')';	// 1200 <

 // >= 1920
 $xlg: '(min-width: ' + $screen-xlg-min + ')';

 $fullhd: '(min-width: 1770px)';

 $xssm: '(max-width: ' + $screen-sm-max + ')';	// 768 -> 991;
 $xssm-d: '(max-device-width: ' + $screen-sm-max + ')';	// 768 -> 991;
 $xssm-d-portrait: '(max-device-width: ' + $screen-sm-max + ') and (orientation: portrait)';	// 768 -> 991;
 $xssm-d-landscape: '(max-device-width: ' + $screen-sm-max + ') and (orientation: landscape)';	// 768 -> 991;

 $mdlg: '(min-width: ' + $screen-md-min + ')';	// 992 >=;
 $mdlg-d: '(min-device-width: ' + $screen-md-min + ')';
 $mdlg-d-portrait: '(min-device-width: ' + $screen-md-min + ') and (orientation: portrait)';
 $mdlg-d-landscape: '(min-device-width: ' + $screen-md-min + ') and (orientation: landscape)';

 // 700 -> 1199
 $smmd: '(min-width: ' + $screen-sm-min + ') and (max-width: ' + $screen-md-max + ')';
 $smmd-d: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' + $screen-md-max + ')';
 $smmd-d-portrait: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' + $screen-md-max + ') and (orientation: portrait)';
 $smmd-d-landscape: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' + $screen-md-max + ') and (orientation: landscape)';

 // <= 1199
 $xssmmd: '(max-width: ' + $screen-md-max + ')';
 $xssmmd-d: '(max-device-width: ' + $screen-md-max + ')';
 $xssmmd-d-portrait: '(max-device-width: ' + $screen-md-max + ') and (orientation: portrait)';
 $xssmmd-d-landscape: '(max-device-width: ' + $screen-md-max + ') and (orientation: landscape)';

 // >= 700
 $smmdlg: '(min-width: ' + $screen-sm-min + ')';
 $smmdlg-d: '(min-device-width: ' + $screen-sm-min + ')';
 $smmdlg-d-portrait: '(min-device-width: ' + $screen-sm-min + ') and (orientation: portrait)';
 $smmdlg-d-landscape: '(min-device-width: ' + $screen-sm-min + ') and (orientation: landscape)';

 // MIXINS
 @mixin xxxs($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $xxxs-d !global;

	 } @else if $mode == dl {
		 $query: $xxxs-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $xxxs-d-portrait !global;

	 } @else {
		 $query: $xxxs !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin xxs($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $xxs-d !global;

	 } @else if $mode == dl {
		 $query: $xxs-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $xxs-d-portrait !global;

	 } @else {
		 $query: $xxs !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin xs($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $xs-d !global;

	 } @else if $mode == dl {
		 $query: $xs-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $xs-d-portrait !global;

	 } @else {
		 $query: $xs !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin sm($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $sm-d !global;

	 } @else if $mode == dl {
		 $query: $sm-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $sm-d-portrait !global;

	 } @else {
		 $query: $sm !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin md($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $md-d !global;

	 } @else if $mode == dl {
		 $query: $md-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $md-d-portrait !global;

	 } @else {
		 $query: $md !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin lg($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $lg-d !global;

	 } @else if $mode == dl {
		 $query: $lg-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $lg-d-portrait !global;

	 } @else {
		 $query: $lg !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin xlg($argument: null) {
	 @media #{$xlg} #{$argument} {
		 @content;
	 }
 }

 @mixin fullhd($argument: null) {
	 @media #{$fullhd} #{$argument} {
		 @content;
	 }
 }

 @mixin dpi($ratio: 1.5, $resolution: 144dpi, $argument: null) {
	 @media (-webkit-min-device-pixel-ratio: #{$ratio}), (min-resolution: #{$resolution}) {
		 @content;
	 }
 }

 @mixin xssm($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $xssm-d !global;

	 } @else if $mode == dl {
		 $query: $xssm-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $xssm-d-portrait !global;

	 } @else {
		 $query: $xssm !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin smmd($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $smmd-d !global;

	 } @else if $mode == dl {
		 $query: $smmd-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $smmd-d-portrait !global;

	 } @else {
		 $query: $smmd !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin mdlg($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $mdlg-d !global;

	 } @else if $mode == dl {
		 $query: $mdlg-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $mdlg-d-portrait !global;

	 } @else {
		 $query: $mdlg !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin xssmmd($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $xssmmd-d !global;

	 } @else if $mode == dl {
		 $query: $xssmmd-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $xssmmd-d-portrait !global;

	 } @else {
		 $query: $xssmmd !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 @mixin smmdlg($mode: null, $argument: null) {
	 @if $mode == d {
		 $query: $smmdlg-d !global;

	 } @else if $mode == dl {
		 $query: $smmdlg-d-landscape !global;

	 } @else if $mode == dp {
		 $query: $smmdlg-d-portrait !global;

	 } @else {
		 $query: $smmdlg !global;
	 }

	 @media #{$query} #{$argument} {
		 @content;
	 }
 }

 /// Triangle
 /// @param {String} $direction - top|bottom|left|right
 /// @param {Length} $size - Width
 /// @param {Color} $color - Color

 @mixin triangle($direction, $width, $height, $color) {
	border-style: solid;
	content: '';
	display: inline-block;
	height: 0;
	width: 0;

	@if $direction == top {
		border-color: transparent transparent $color transparent;
		border-width: 0 $width/2 $height $width/2;
	}

	@else if $direction == bottom {
		border-color: $color transparent transparent transparent;
		border-width: $height $width/2 0 $width/2;
	}

	@else if $direction == left {
		border-color: transparent $color transparent transparent;
		border-width: $width/2 $height $width/2 0;
	}

	@else if $direction == right {
		border-color: transparent transparent transparent $color;
		border-width: $height/2 0 $height/2 $width;
	}

	@else if $direction == top right or $direction == right top {
		border-color: transparent $color transparent transparent;
		border-width: 0 $width $height 0;
	}

	@else if $direction == top left or $direction == left top {
		border-color: transparent transparent transparent $color;
		border-width: 0 0 $height $width;
	}

	@else if $direction == bottom right or $direction == right bottom {
		border-color: transparent $color transparent transparent ;
		border-width: $height $width 0 0;
	}

	@else if $direction == bottom left or $direction == left bottom {
		border-color: transparent transparent transparent $color;
		border-width: $height 0 0 $width;
	}
}