/* -----------------------------
 * HEADING
 * ----------------------------*/
%headings-1-to-6 {
	color: $headings-color;
	@include rem( margin, $headings-margins);
	font: {
		family: $headings-font-family;
		weight: $headings-font-weight;
	}
  line-height: 1.2;

  a {
    text-decoration: none;
  }
}

/* -----------------------------
 * GRID
 * ----------------------------*/
%container {
	width: 90%;
	@include rem(max-width, $container-width);
	margin: {
		right: auto;
		left: auto;
	}
}

/* -----------------------------
 * FORMS
 * ----------------------------*/
%form-item {
  @include rem(font-size, 12px);
	@include rem(padding, 7px 10px);
	box-sizing: border-box;
	appearance: none;
	display: block;
	width: 100%;
	background: {
		image: none;
	}
	border: 1px solid $gray;

	&:focus {
		outline: none;
		border-color: darken($gray, 10%);
	}

	&::placeholder {
		color: $black-lighter;
		opacity: 1;
    text-transform: uppercase;
	}

	&:disabled,
	&[readonly] {
		background-color: darken($gray-lighter, 5%);
		opacity: 1;
	}

	&:disabled {
		color: $gray-darker;
		cursor: pointer;
	}

	&.error {
		border: {
			color: $red;
		}

		+ .description {
			color: $red;
		}
	}
}


/* ---------------------------
 * BUTTONS
 * --------------------------*/

%button {
	@include rem(padding, 10px 25px);
  @include rem(font-size, 16px);
  border-radius: 30px;
  font-weight: 700;
	display: inline-block;
	text-align: center;
	text-decoration: none;
}

%button-primary {
  @include rem(padding, 12px 25px);
	background: $primary-color;
	color: $white;

	&:hover,
	&:focus {
		background: $primary-color-darker;
    color: $white;
	}
}

%button-secondary {
	background: $secondary-color;
	color: $white;

	&:hover,
	&:focus {
		background: darken($secondary-color, 10%);
	}
}

%button-warning {
	background: $warning-color;
	text-transform: uppercase;
	font-weight: 700;

	&:hover,
	&:focus {
		background: darken($warning-color, 10%);
	}
}

%button-outline {
	border: 2px solid $red;
	color: $red;

	&:hover,
	&:focus {
		background: $red;
		color: $white;
	}
}
