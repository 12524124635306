#user-login,
.user-info-from-cookie,
#user-pass {
  @include rem(margin-top, 100px);
  @include rem(padding, 60px 80px);
  background: $white;
  box-shadow: 0 3px 15px $gray;
  width: 90%;
  max-width: 650px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  @include xssm {
    @include rem(padding, 30px 40px);
  }
  h1 {
    @include rem(margin, 0 0 60px);
    font-family: $headings-font-family;
    text-align: left;
    color: $black;

    &:after {
      height: 3px;
      background: $black;
    }
  }

  #edit-submit {
    @include rem(padding, 20px);
    display: block;
    background: $primary-color-darker;
    color: $white;
    text-transform: uppercase;
    font-weight: 300;
    border-radius: 5px;
    border: none;
    width: 100%;

    &:hover,
    &:focus {
      background: $black;
      cursor: pointer;
    }
  }

  .user-login-links {
    text-align: center;

    a {
      color: $primary-color-darker;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .description,
  label {
    display: none;
  }

  .form-item {
    margin: 0;
    width: auto;

    &.form-item-mail {
      input.form-text {
        background: url('../images/icon-email.png') 14px center no-repeat;

        &:-webkit-autofill {
          background-image: url('../images/icon-email.png');
          background-repeat: no-repeat;
          background-position: 14px center;
        }

        /* ECRAN RETINA */
        @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
        only screen and (-o-max-device-pixel-ratio: 3/2),
        only screen and (max--moz-device-pixel-ratio: 1.5),
        only screen and (max-device-pixel-ratio: 1.5) {
          background: url('../images/icon-email.png') 14px center no-repeat;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
          background: url('../images/icon-email@2x.png') 14px center no-repeat;
          background-size: 23px 19px;
        }
      }
    }

    &.form-item-name {
      input.form-text {
        background: url('../images/icon-user.png') 16px center no-repeat;

        &:-webkit-autofill {
          background-image: url('../images/icon-user.png');
          background-repeat: no-repeat;
          background-position: 16px center;
        }

        /* ECRAN RETINA */
        @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
        only screen and (-o-max-device-pixel-ratio: 3/2),
        only screen and (max--moz-device-pixel-ratio: 1.5),
        only screen and (max-device-pixel-ratio: 1.5) {
          background: url('../images/icon-user.png') 16px center no-repeat;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
          background: url('../images/icon-user@2x.png') 16px center no-repeat;
          background-size: 18px 22px;
        }
      }
    }
    input.form-text {
      @include rem(padding, 20px 20px 20px 50px);
      @include rem(margin-bottom, 20px);
      border-radius: 5px;
      border: none;
      border: 1px solid darken($gray-lighter, 5%);
      box-sizing: border-box;
      width: 100%;

      &:focus {
        box-shadow: 0 0 10px $primary-color;
      }


      &#edit-pass {
        background: url('../images/icon-lock.png') 18px center no-repeat;

        &:-webkit-autofill {
          background-image: url('../images/icon-lock.png');
          background-repeat: no-repeat;
          background-position: 18px center;
        }
        /* ECRAN RETINA */
        @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
        only screen and (-o-max-device-pixel-ratio: 3/2),
        only screen and (max--moz-device-pixel-ratio: 1.5),
        only screen and (max-device-pixel-ratio: 1.5) {
        	background: url('../images/icon-lock.png') 18px center no-repeat;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
        	background: url('../images/icon-lock@2x.png') 18px center no-repeat;
          background-size: 14px 23px;
        }
      }
    }
  }
}

.page-user {
  .tabs {
    display: none;
  }

  &.logged-in {
    .tabs {
      display: flex;
    }
  }
}
