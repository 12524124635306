*:focus,
*:active {
	outline: none;
	box-shadow: none;
}
body {
	@include rem(font-size, 15px);
	font-family: $global-font-family;
	line-height: $global-line-height;
  color: $black-lighter;
  /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;

  &.fixed {
    &:before {
      display: block;
      content:"";
      background: rgba($black, 0.3);
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 200;
    }
  }
}

div {
  min-height: 0;
  min-width: 0;
}

h1 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, $headings-1-font-size);
    weight: 300;
	}
  color: $primary-color;

  &:after {
    @include rem(margin, 10px 0);
    content: "";
    display: block;
    height: 1px;
    width: 60px;
    background: $primary-color;
  }

  @include xssm {
    @include rem(font-size, 36px);
  }
}

h2 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, $headings-2-font-size);
	}
  @include rem(margin-bottom, 70px);

  .node-type-service & {
    @include rem(margin-bottom, 30px);
    font-family: $global-font-family;
    color: $primary-color;
    font-weight: 300;
  }

  @include xssm {
    @include rem(font-size, 38px);
  }
}

h3 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, $headings-3-font-size);
    weight: normal;
	}
}

h4 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, $headings-4-font-size);
	}
}

h5 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, $headings-5-font-size);
	}
}

h6 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, $headings-6-font-size);
	}
}

img {
	max-width: 100%;
	height: auto;
}

a {
	transition: all linear .2s;
	color: $primary-color;
  text-decoration: underline;

  &:hover,
	&:focus {
    text-decoration: none;
    color: $primary-color-darker;
	}

  .menu &{
    text-decoration: none;
  }
}

strong {
  color: $black;
}
