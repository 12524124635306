.form-type-bef-checkbox {
  input {
    display: none;

    &:checked + label {
      &:before {
        background: $primary-color;
      }
    }
  }

  label {
    @include rem(padding, 5px 0);
    display: block;

    &:before {
      @include rem(margin-right, 10px);
      display: inline-block;
      content:"";
      border: 2px solid $white;
      width: 12px;
      height: 12px;
      vertical-align: middle;
      box-shadow: 0px 0px 0px 1px $gray;
    }
  }
}

form {
  .form-text,
  textarea {
    @extend %form-item;
  }
}

.webform-component-select {
  display: flex;
  align-items: center;
  position: relative;

  @include xssm {
    display: block;
  }

  &:after {
    @include custom-font('\EA0F');
    @include rem(font-size, 8px);
    position: absolute;
    right: 10px;
    color: $primary-color;
    pointer-events: none;

    @include xssm {
      bottom: 10px;
    }
  }

  label {
    @include rem(margin-right, 5px);
    white-space: nowrap;
  }

  select {
    @include rem(padding, 7px 10px);
    @include rem(font-size, 12px);
    background: none;
    border: 1px solid $gray;
    width: 100%;
    appearance: none;
    border-radius: 0;
    text-transform: uppercase;
  }
}

form {
  .webform-submit {
    @extend %button;
    @extend %button-primary;
    border: none;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}
