.view-agenda {
  &.view-display-id-page_1,
  &.view-display-id-block_2 {
    @extend %container;

    > .view-content {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1%;
      margin-right: -1%;

      @include xssm {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }

      .views-row {
        @include rem(padding-bottom, 20px);
        border: 1px solid $gray;
        box-sizing: border-box;
        width: 23%;
        flex: 0 0 23%;
        margin: 0 1% 2%;

        @include xssm {
          @include rem(margin-bottom, 20px);
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }

        h3 {
          @include rem(font-size, 26px);
          @include rem(padding-bottom, 10px);
          @include rem(margin-bottom, 5px);
          border-bottom: 1px dashed $gray;
          a {
            color: $secondary-color;
            font-weight: 600;
          }
        }

        .views-field-field-image {
          @include rem(margin-bottom, 20px);
          position: relative;

          .image {
            &:before {
              display: block;
              content:"";
              padding-top: 75%;
            }
          }

          img {
            display: block;
          }

          .service-1,
          .service-2,
          .service-3,
          .service-4 {
            background: $primary-color-lighter;
          }
        }

        .views-field-title,
        .views-field-field-service,
        .views-field-field-date,
        .views-field-field-date-de-fin,
        .views-field-field-organisateur,
        .views-field-body,
        .views-field-path {
          @include rem(padding, 0 20px);
        }

        .views-field-field-service {
          a {
            @include rem(font-size, 14px);
            color: $gray-darker;
          }
        }

        .views-field-field-date,
        .views-field-field-date-de-fin,
        .views-field-field-organisateur {
          @include rem(font-size, 14px);
          font-weight: 600;
          color: $black-lighter;
        }

        .views-field-body {
          color: $black-lighter;
        }

        .views-field-path {
          a {
            color: $primary-color;
            font-weight: 600;
            border-bottom: 1px solid $primary-color;
            text-decoration: none;
          }
        }
      }
    }
  }

  &.view-display-id-block_1 {
    .view-footer {
      @include rem(padding, 50px 0 70px);
      text-align: center;
    }
    .view-content {
      display: flex;
      justify-content: space-between;

      @include sm {
        flex-wrap: wrap;
      }

      @include xs {
        display: block;
      }

      .views-row {
        @include rem(padding, 0 15px 15px);
        flex: 1;

        @include sm {
          flex: 0 0 50%;
          box-sizing: border-box;
        }
      }

      .views-field-field-image {
        border-top: 1px solid $gray;
        border-left: 1px solid $gray;
        border-right: 1px solid $gray;
        position: relative;

        img {
          display: block;
        }
      }

      .views-field-field-service,
      .views-field-field-date,
      .views-field-field-date-de-fin,
      .views-field-field-organisateur,
      .views-field-body,
      .views-field-path {
        @include rem(padding, 0 15px);
        margin: 0;
        border-left: 1px solid $gray;
        border-right: 1px solid $gray;

        p {
          margin: 0;
        }
      }

      .views-field-title {
        @include rem(padding, 20px 15px 15px);
        border-left: 1px solid $gray;
        border-right: 1px solid $gray;

        h3 {
          @include rem(font-size, 24px);
          @include rem(padding-bottom, 15px);
          font-family: $global-font-family;
          font-weight: 600;
          margin: 0;
          line-height: 1;
          background-image: linear-gradient(to right, $gray-darkest 33%, rgba(255,255,255,0) 0%);
          background-position: bottom;
          background-size: 5px 1px;
          background-repeat: repeat-x;

          a {
            color: $secondary-color;
          }
        }
      }

      .views-field-field-service {
        a {
          @include rem(font-size, 13px);
          color: $gray;
          text-decoration: none;
        }
      }

      .views-field-field-date,
      .views-field-field-date-de-fin,
      .views-field-field-organisateur {
        @include rem(font-size, 13px);
        color: $black-lighter;
        font-weight: 600;
      }

      .views-field-field-organisateur {
        @include rem(padding-bottom, 30px);
      }

      .views-field-body {
        @include rem(font-size, 14px);
        @include rem(padding-bottom, 30px);
        color: $black-lighter;
      }

      .views-field-path {
        @include rem(padding-bottom, 15px);
        border-bottom: 1px solid $gray;

        a {
          @include rem(font-size, 14px);
          display: inline-block;
          border-bottom: 1px solid $primary-color;
          text-decoration: none;

          &:hover,
          &:focus {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.view-fiche {
  &.view-id-fiche {
    @extend %container;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include xssmmd {
      display: block;
    }

    > .view-filters {
      width: 25%;
      margin-right: 3%;

      @include lg {
        position: sticky;
        top: 20px;
      }

      @include xssmmd {
        width: 100%;
        margin-right: 0;
      }

      &:before {
        @include rem(margin-bottom, 30px);
        @include rem(font-size, 30px);
        content: "Filtres";
        display: block;
        border-bottom: 1px solid $primary-color;

      }

      form {
        position: relative;
      }

      .views-exposed-form {
        .views-exposed-widgets {
          @include md {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
          }

          #edit-title-wrapper {
            @include md {
              flex: 0 0 100%;
              box-sizing: border-box;
            }
          }
        }

        .views-exposed-widget {
          margin: 0;
          @include rem(margin-bottom, 40px);
          padding: 0;
          float: none;

          @include md {
            flex: 1;
          }

          > label {
            @include rem(font-size, 18px);
            @include rem(padding-bottom, 10px);
            @include rem(margin-bottom, 10px);
            text-transform: uppercase;
          }

          .form-item {

            &.form-type-radio,
            &.form-type-bef-checkbox {
              position: relative;
              display: flex;
              align-items: center;

              &:before {
                @include rem(margin-right, 5px);
                display: inline-block;
                content: "";
                width: 15px;
                height: 15px;
                border: 1px solid $gray;
                vertical-align: middle;
              }

              input {
                display: none;

                &:checked + label {
                  &:before {
                    opacity: 1;
                  }
                }
              }

              label {
                font-weight: 400;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: flex-start;

                &:before {
                  @include custom-font('\EA12');
                  @include rem(font-size, 11px);
                  display: block;
                  position: absolute;
                  left: 3px;
                  top: 14px;
                  background: none;
                  border: none;
                  box-shadow: none;
                  opacity: 0;
                }

                &:after {
                  @include rem(font-size, 23px);
                  @include rem(margin-right, 5px);
                  @include rem(margin-left, 10px);
                }
              }

              &.form-item-edit-field-type-tid-8 {
                label {
                  &:after {
                    @include custom-font('\EA06');
                  }
                }
              }

              &.form-item-edit-field-type-tid-10 {
                label {
                  &:after {
                    @include custom-font('\EA07');
                  }
                }
              }

              &.form-item-edit-field-type-tid-9 {
                label {
                  &:after {
                    @include custom-font('\EA0C');
                  }
                }
              }

              &.form-item-edit-field-thematique-tid-5 {
                label {
                  &:after {
                    @include custom-font('\EA03');
                  }
                }
              }

              &.form-item-edit-field-thematique-tid-3 {
                label {
                  &:after {
                    @include custom-font('\EA0E');
                  }
                }
              }

              &.form-item-edit-field-thematique-tid-4 {
                label {
                  &:after {
                    @include custom-font('\EA09');
                  }
                }
              }

              &.form-item-edit-field-action-tid-6 {
                label {
                  &:after {
                    @include custom-font('\EA08');
                  }
                }
              }

              &.form-item-edit-field-action-tid-7 {
                label {
                  &:after {
                    @include custom-font('\EA04');
                  }
                }
              }
            }
          }
        }

        .views-submit-button {
          position: absolute;
          top: 15px;
          right: 15px;
          background: $secondary-color;

          &:before {
            @include custom-font('\EA0D');
            @include rem(font-size, 18px);
            position: absolute;
            color: $white;
            top: 11px;
            left: 11px;
          }

          input.form-submit {
            padding: 0;
            margin: 0;
            cursor: pointer;
            background: transparent;
            border: none;
            font-size: 0;
            width: 41px;
            height: 41px;
            vertical-align: middle;
          }
        }

        #edit-title-wrapper {
          @include rem(padding, 15px);
          background: $secondary-color-lighter;

          input {
            @include rem(padding, 13px 11px);
            border-color: $secondary-color;
          }
        }

        #edit-field-type-tid-wrapper {
          @include rem(padding, 0 15px);
          color: $secondary-color;
        }

        #edit-field-thematique-tid-wrapper {
          @include rem(padding, 0 15px);
          color: $primary-color;

          > label {
            border-bottom: 1px solid $primary-color;
          }
        }

        #edit-field-action-tid-wrapper {
          @include rem(padding, 0 15px);
          color: $tertiary-color;

          > label {
            border-bottom: 1px solid $tertiary-color;
          }
        }
      }
    }

    > .view-content {
      width: 72%;
      display: flex;
      flex-wrap: wrap;
      margin-left: -1%;
      margin-right: -1%;

      @include xssmmd {
        width: 100%
      }

      @include xs {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }

      .views-row {
        @include rem(padding-bottom, 20px);
        border: 1px solid $gray;
        box-sizing: border-box;
        width: 31.333%;
        flex: 0 0 31.333%;
        margin: 0 1% 2%;
        display: flex;
        flex-direction: column;

        @include xs {
          @include rem(margin-bottom, 20px);
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }

        .views-field-field-type {
          @include rem(margin, 0 15px);
          @include rem(padding, 10px 0);
          @include rem(font-size, 11px);
          border-bottom: 1px dashed $gray;
          color: $secondary-color;
          text-transform: uppercase;

          span {
            display: flex;
            align-items: center;

            &:before {
              @include rem(margin-right, 7px);
              @include rem(font-size, 20px)
              display: inline-block;
            }

            &.type-9 {
              &:before {
                @include custom-font('\EA0C');
              }
            }

            &.type-8 {
              &:before {
                @include custom-font('\EA06');
              }
            }

            &.type-10 {
              &:before {
                @include custom-font('\EA07');
              }
            }
          }
        }

        h3 {
          @include rem(font-size, 26px);
          @include rem(padding-top, 15px);
          @include rem(padding-bottom, 10px);
          @include rem(margin, 0 15px);
          @include rem(margin-bottom, 5px);

          a {
            color: $secondary-color;
            font-weight: 600;
          }
        }

        .views-field-field-pdf {
          @include rem(margin, 0 15px);
          @include rem(padding, 15px 0 0);
          border-top: 1px dashed $gray;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .field-content {
            display: flex;
            align-items: center;

            .link {
              flex: 1;
              text-align: right;

              a {
                @include rem(padding, 0 20px);
                color: $primary-color;
                border-color: $primary-color;
                border-width: 1px;
                font-weight: normal;

                &:hover,
                &:focus {
                  background: $primary-color;
                  color: $white;
                }
              }
            }
          }

          .icon {
            font-size: 0;

            &:before {
              @include rem(font-size, 20px);
              color: $white;
              vertical-align: middle;
            }

            &[class*="icon-thematique"] {
              display: inline-block;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-radius: 50%;
              background: $primary-color;
            }

            &[class*="icon-action"] {
              @include rem(margin-right, 5px);
              display: inline-block;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-radius: 50%;
              background: $tertiary-color;
            }

            &-thematique-3 {
              &:before {
                @include custom-font('\EA0E');
              }
            }

            &-thematique-4 {
              &:before {
                @include custom-font('\EA09');
              }
            }

            &-thematique-5 {
              &:before {
                @include custom-font('\EA03');
              }
            }

            &-action-6 {
              &:before {
                @include custom-font('\EA08');
              }
            }

            &-action-7 {
              &:before {
                @include custom-font('\EA04');
              }
            }
          }
        }
      }
    }

    > .view-footer {
      @include rem(margin-top, 20px);
      flex: 0 0 100%;
  
      @include lg {
        align-self: flex-end;
        flex: 0 0 72%;
        margin-left: auto;
        margin-right: 0;
      }

      .view-fiche {
        display: block;
        width: 100%;
        max-width: none;
      }

      .view-content {
        display: block;
        margin: 0;
        width: 100%;
        
        h3 {
          @include rem(font-size, 21px);
          @include rem(margin, 20px 0 10px 0);
        }
      }

      .views-row {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;

        .views-field-field-pdf {
          border-top: none;
          padding: 0;
          margin: 0;

          .field-content .link {
            margin-left: -10px;
            text-align: left;
          }
        }
      }
    }
  }
}

.view-plantes {
  @extend %container;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @include xssmmd {
    display: block;
  }

  > .view-filters {
    width: 25%;
    margin-right: 3%;

    @include xssmmd {
      width: 100%;
      margin-right: 0;
    }

    &:before {
      @include rem(margin-bottom, 30px);
      @include rem(font-size, 30px);
      content: "Filtres";
      display: block;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
    }

    form {
      position: relative;
    }

    .views-exposed-form {
      .views-exposed-widgets {
        @include md {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
        }

        #edit-title-wrapper {
          @include md {
            flex: 0 0 100%;
            box-sizing: border-box;
          }
        }

        .views-submit-button {
          position: absolute;
          top: 15px;
          right: 15px;
          background: $secondary-color;

          &:before {
            @include custom-font('\EA0D');
            @include rem(font-size, 18px);
            position: absolute;
            color: $white;
            top: 11px;
            left: 11px;
          }

          input.form-submit {
            padding: 0;
            margin: 0;
            cursor: pointer;
            background: transparent;
            border: none;
            font-size: 0;
            width: 41px;
            height: 41px;
            vertical-align: middle;
          }
        }

        #edit-title-wrapper,
        #edit-combine-wrapper {
          @include rem(padding, 15px);
          background: $secondary-color-lighter;

          input {
            @include rem(padding, 13px 11px);
            border-color: $secondary-color;
          }
        }

        .bef-select-as-links {
          .form-item {
            display: flex;
            flex-wrap: wrap;

            a {
              @include rem(margin-right, 5px);
              @include rem(margin-bottom, 5px);
              @include rem(padding, 0 5px);
              background: $secondary-color-lighter;
              text-decoration: none;
              color: $secondary-color;
              display: inline-block;
              width: 20px;
              text-align: center;

              &.active {
                background: $secondary-color;
                color: $white;
              }

              &:hover,
              &:focus {
                color: $secondary-color-darker;
              }
            }

            #edit-title-1-all {
              a {
                width: 90px;
              }
            }
          }
        }
      }

      .views-exposed-widget {
        margin: 0;
        @include rem(margin-bottom, 40px);
        padding: 0;
        float: none;

        @include md {
          flex: auto;
        }

        > label {
          @include rem(font-size, 18px);
          @include rem(padding-bottom, 10px);
          @include rem(margin-bottom, 10px);
          text-transform: uppercase;
          border-bottom: 1px solid $primary-color;
          color: $primary-color;
        }

        &#edit-field-floraison-tid-wrapper {
          @include md {
            margin: 0 20px;
          }
        }

        &#edit-field-exposition-tid-wrapper {
          @include md {
            margin-right: 20px;
          }
        }

        .form-item {

          &.form-type-radio,
          &.form-type-bef-checkbox {
            position: relative;
            display: flex;
            align-items: center;

            &:before {
              @include rem(margin-right, 10px);
              display: inline-block;
              content: "";
              width: 15px;
              height: 15px;
              border: 1px solid $gray;
              vertical-align: middle;
            }

            input {
              display: none;

              &:checked + label {
                &:before {
                  opacity: 1;
                }
              }
            }

            label {
              font-weight: 400;
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              justify-content: flex-start;
              color: $secondary-color;
              padding: 0;

              &:before {
                @include custom-font('\EA12');
                @include rem(font-size, 11px);
                display: block;
                position: absolute;
                left: 3px;
                top: 8px;
                background: none;
                border: none;
                box-shadow: none;
                color: $gray-darkest;
                opacity: 0;
              }

              &:after {
                @include rem(font-size, 23px);
                @include rem(margin-right, 5px);
                @include rem(margin-left, 10px);
              }
            }
          }
        }
      }
    }
  }

  > .view-content {
    width: 74%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -1%;
    margin-right: -1%;

    @include xssmmd {
      width: 100%
    }

    @include xs {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    .views-row {
      @include rem(padding-bottom, 20px);
      border: 1px solid $gray;
      box-sizing: border-box;
      width: 31.333%;
      flex: 0 0 31.333%;
      margin: 0 1% 2%;
      display: flex;
      flex-direction: column;
      position: relative;

      @include xs {
        @include rem(margin-bottom, 20px);
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &:hover {
        .views-field-nothing {
          .group-details {
            opacity: 1;
          }
        }
      }

      h2 {
        @include rem(margin, 15px 15px 5px 15px);
        @include rem(font-size, 24px);
        color: $secondary-color;
        font-weight: bold;
        text-align: left;

        &:after {
          display: none;
        }

        a {
          color: $secondary-color;
        }
      }

      h3 {
        @include rem(margin, 0 15px 0 15px);
        @include rem(font-size, 14px);
        font-style: italic;
        font-family: $global-font-family;
      }

      .views-field-field-plante-indig-ne {
        position: absolute;
        bottom: 0;
        right: 10px;
        line-height: 1;
        font-size: 45px;
      }
    }
  }

  .pager--infinite-scroll {
    flex: 0 0 100%;

    .views_infinite_scroll-ajax-loader {
      width: 74%;
      float: right;
    }
  }

  .views-field-nothing {
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    .image {
      &:before {
        display: block;
        content: "";
        padding-top: 139.3%;
        background: $primary-color-lighter url('../images/icon-fleur.svg') center center no-repeat;
        background-size: 70px auto;
      }
    }

    .group-details {
      @include rem(padding, 20px);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      opacity: 0;
      transition: all linear .2s;

      .btn {
        @include rem(padding, 0 20px);
        font-weight: normal;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          color: $white;

          strong {
            color: $white;
          }
        }
      }
    }
  }
}
