.section-header {
  display: flex;

  @include xssm {
    position: relative;
  }

  #header {
    flex: 1;
  }
}
.node-plantes-melliferes {
  @include rem(padding-top, 100px);
  width: 90%;
  max-width: 810px;
  margin: 0 auto;
  position: relative;

  &:after {
    display: none;
  }

  @include xssm {
    padding-top: 0;
  }

  @include mdlg {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .field-name-field-image {

    @include mdlg {
      flex: 0 0 100%;
    }

    img {
      width: 100%;
    }
  }

  .group-text {
    flex: auto;

    @include xssm {
      flex: 0 0 100%;
    }
  }

  .group-titre {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to right, #979797 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;

    @include xssm {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @include mdlg {
      flex: 0 0 100%;
    }

    .btn {
      @include rem(padding, 1px 15px);
      color: $primary-color;
      border-color: $primary-color;
      border-width: 1px;
      font-weight: 400;

      &-outline {
        &:hover,
        &:focus {
          background: $primary-color;
          color: $white;
        }
      }

      &-primary {
        @include rem(margin-left, 10px);
        color: $white;
      }
    }
  }

  .group-left,
  .group-right {
    @include mdlg {
      flex: 0 0 350px;
    }
  }

  h1 {
    @include rem(font-size, 26px);
    color: $secondary-color;
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

  .field-name-field-nom-latin {
    @include rem(padding-bottom, 15px);
    @include rem(margin-bottom, 10px);
    font-style: italic;
  }

  h3 {
    @include rem(font-size, 18px);
    @include rem(margin-bottom, 10px);
    @include rem(padding-bottom, 10px);
    @include rem(margin-top, 40px);
    font-family: $global-font-family;
    color: $primary-color;
    background-image: linear-gradient(to right, #979797 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
  }

  .field-group-div {

    @include mdlg {
      max-width: 350px;
    }

    &.group-titre,
    &.group-text {
      max-width: none;
    }
  }

  .field-name-field-rusticite,
  .field-name-field-distance-de-plantation,
  .field-name-field-ou,
  .field-name-field-cultivars {
    @include mdlg {
      max-width: 350px;
    }

    .field-label {
      display: block;
      @include rem(font-size, 18px);
      @include rem(margin-bottom, 10px);
      @include rem(padding-bottom, 10px);
      @include rem(margin-top, 40px);
      font-family: $global-font-family;
      color: $primary-color;
      background-image: linear-gradient(to right, #979797 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;
      font-weight: normal;
    }
  }
}

.node-agenda {
  @include rem(padding-top, 100px);
  width: 90%;
  max-width: 810px;
  margin: 0 auto;
  position: relative;

  @include xssm {
    padding-top: 0;
  }

  .field-name-field-image {
    @include rem(margin-right, 190px);

    @include xssm {
      margin-right: 0;
    }

    img {
      display: block;
    }
  }

  .group-infos {
    @include rem(padding, 20px 20px 30px);
    border: 1px solid $primary-color;
    box-shadow: 0 0 20px $gray;
    position: absolute;
    top: 220px;
    right: 0;
    width: 250px;
    background: $white;

    @include xssm {
      @include rem(margin-top, 50px);
      position: relative;
      width: auto;
      top: 0;
    }

    h3 {
      @include rem(padding-right, 50px);
      color: $primary-color;

      &:after {
        @include rem(margin-top, 10px);
        display: block;
        background: $primary-color;
        content:"";
        width: 30px;
        height: 3px;
      }
    }

    .field-name-field-details {
      @include rem(margin, 20px 0 30px);
      p {
        @include rem(font-size, 14px);
        margin: 0;
        color: $gray;
      }
    }

    .field-name-field-organisateur {
      @include rem(margin-bottom, 10px);

      .field-label {
        @include rem(margin-bottom, 10px);
        color: $secondary-color;
        text-transform: uppercase;
      }
    }
  }

  .field-name-title,
  .field-name-body {
    @include rem(padding-right, 320px);

    @include xssm {
      padding-right: 0;
    }
  }

  h1 {
    color: $secondary-color;
    line-height: 1;

    &:after {
      display: none;
    }
  }

  .field-name-body {
    @include rem(font-size, 15px);
  }

  .btn {
    @include rem(margin-top, 100px);
    @include rem(padding, 5px 20px);
    color: $primary-color;
    border-color: $primary-color;

    &:hover,
    &:focus {
      background: $primary-color;
      color: $white;
    }
  }
}

#highlighted {
  img {
    width: 100%;
  }
}

#sub-content {
  @include rem(margin-top, 200px);

  @include xssm {
    @include rem(margin-top, 20px);
  }

  .page-node-3 & {
    #block-views-texte-block-1 {
      > h2 {
        @include rem(margin-bottom, 200px);

        @include xssm {
          margin-bottom: 80px;
        }
      }
    }
    .block > .content {
      @include rem(padding-bottom, 100px);
      background: $secondary-color-lighter;

      > .view-texte {
        @extend %container;
        max-width: 900px;

        @include xssm {
          transform: translateY(-40px);
        }
      }
    }
  }

  .page-node-2 &,
  .page-node-18 & {
    position: relative;

    &:before {
      display:block;
      content:'';
      clear: both;
      height: 200px;
      width: 100%;
      background: $white;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .page-node-2 &,
  .page-node-4 &,
  .page-node-18 & {
    background: $secondary-color-lighter;

    @include lg {
      padding-bottom: 70px;
    }

    @include xssmmd {
      margin-bottom: 40px;
    }

    &:after {
      display:block;
      content:'';
      clear: both;
    }

    .region-sub-content {
      @extend %container;
      @include rem(padding, 0 100px);
      box-sizing: border-box;
      position: relative;

      @include xssm {
        @include rem(margin-top, 100px);
        padding: 0;
      }
    }

    .content {
      @include rem(padding-bottom, 30px);

      @include xssm {
        @include rem(padding-bottom, 30px);
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          padding: 0;
          margin: 0;
          list-style: none;

        }
      }
    }

    #block-views-texte-block-3 {
      @include rem(padding-top, 300px);
      margin-left: calc(28% + 140px);
      position: relative;

      @include xssm {
        @include rem(padding-top, 40px);
        margin-left: 0;
      }

      .field-name-field-image {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%);

        @include lg {
          margin-right: -100px;
        }

        @include xssm {
          position: relative;
          transform: translateY(0);
        }
      }

      h2 {
        text-align: left;

        &:after {
          margin-left: 0;
        }
      }

      .intro {
        @include rem(font-size, 18px);
        color: $secondary-color;
      }
    }

    #block-views-texte-block-2 {
      @include rem(padding, 30px);
      transform: translateY(-40px);
      background: $white;
      max-width: 28%;
      float: left;
      border: 1px solid $tertiary-color;
      box-shadow: 0 0 20px $gray;

      @include xssm {
        float: none;
        max-width: 90%;
      }

      .content {
        padding-bottom: 0;
      }

      ul {
        li {
          border: none;
          box-shadow: none;
        }
      }
      .intro {
        @include rem(font-size, 18px);
        @include rem(margin-bottom, 50px);
        color: $tertiary-color;
      }

      .field-name-field-title {
        @include rem(font-size, 36px);
        @include rem(margin-bottom, 40px);
        font-weight: bold;
        color: $tertiary-color;

        &:after {
          display: block;
          content:"";
          width: 30px;
          height: 4px;
          background-color: $tertiary-color;
        }
      }

      .view-footer {
        @include rem(margin-top, 50px);

        > h3 {
          @include rem(font-size, 28px);
          color: $secondary-color;
          font-family: $global-font-family;
          font-weight: bold;
        }

        .views-field-field-image {
          @include rem(margin-right, 20px);
          border-radius: 50%;
          overflow: hidden;
          width: 35%;
          float: left;
          position: relative;

          img {
            display: block;
          }

          .image {
            &:before {
              padding-top: 100%;
            }
          }
        }

        .views-field-title-1 {
          h3 {
            @include rem(font-size, 23px);
            @include rem(margin-bottom, 10px);
            color: $black-lighter;
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        .views-field-field-fonction {
          @include rem(margin-bottom, 10px);
          line-height: 1.4;
        }

        .views-field-field-fonction,
        .views-field-field-phone,
        .views-field-field-email {
          margin-left: calc(35% + 20px);
        }
      }
    }
  }

  .page-node-4 & {
    .region-sub-content {
      @include rem(padding-bottom, 150px);
    }
  }
}

#ter-content {
  h2 {
    @include rem(font-size, 36px);
    color: $primary-color-darker;
    text-align: left;
    font-family: $headings-font-family;

    &:after {
      margin-left: 0;
      background: $primary-color-darker;
    }
  }

  .page-node-2 & {
    #block-views-texte-block-4 {
      @include rem(padding, 100px 0);
      max-width: 1240px;
      margin: 0 auto;

      @include xssm {
        padding: 0;
      }

      > .content {
        @include lg {
          @include rem(margin-left, -100px);
        }
      }
    }

    .entity-paragraphs-item > .content {
      display: flex;
      align-items: flex-start;

      @include xssm {
        display: block;
        max-width: 90%;
        margin: auto;
      }
    }

    .field-name-field-image {
      @include rem(margin-right, 40px);
      flex: 0 0 35%;
      margin-top: -80px;

      @include xssm {
        margin: 0;
      }
    }

    .field-name-field-body {
      @include rem(padding-right, 200px);

      @include xssm {
        padding-right: 0;
      }
    }
  }

  .page-node-4 & {
    &:after {
      display: block;
      content:"";
      clear:left;
    }

    .region-ter-content {
      @extend %container;
      @include rem(padding, 0 100px);
      box-sizing: border-box;

      @include xssm {
        padding: 0;
      }
    }

    #block-views-texte-block-4 {
      width: calc(28% + 60px);
      float: left;

      @include xssm {
        float: none;
        width: 100%;
      }
    }

    #block-views-agenda-block-3 {
      @include rem(padding-top, 140px);
      margin-left: calc(28% + 140px);

      @include xssm {
        margin-left: 0;
        @include rem(padding-top, 20px);
      }

      .view-agenda {
        width: 100%;
      }

      h2 {
        @include rem(font-size, 28px);
        color: $secondary-color;
        font-family: $global-font-family;
        max-width: 300px;

        &:after {
          background: $secondary-color;
        }
      }
      .view-content {
        display: flex;
        flex-wrap: nowrap;
        margin-left: -10px;
        margin-right: -10px;

        @include xssm {
          display: block;
          margin-left: 0;
          margin-right: 0;
        }

        .views-row {
          flex: 0 1 50%;
          margin: 0 10px;

          @include xssm {
            margin: 0 0 20px;
          }

          h3 {
            @include rem(font-size, 24px);
            a {
              color: $secondary-color;
            }
          }
        }
      }

      .view-footer {
        @include rem(margin, 60px 0);
        text-align: center;
      }
    }

    .field-name-field-image {
      @include lg {
        @include rem(margin-left, -200px);
        @include rem(margin-top, -70px);
      }
    }

    .field-name-field-body {
      @include rem(margin-top, 70px);
    }
  }
}

.region-highlighted {
  position: relative;
}
