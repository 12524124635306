footer {
  @include rem(padding, 80px 0 60px);
  @include rem(margin-top, 100px);
  background: $gray-lighter;

  .container {
    display: flex;

    @include xssm {
      display: block;
    }

    .email {
      @include rem(margin-top, 15px);
    }

    .copyright {
      flex: 0 0 20%;
      text-align: right;

      .copy,
      .sign {
        @include rem(font-size, 14px);
        margin: 0;

        a {
          color: $black-lighter;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $primary-color;
            text-decoration: underline;
          }
        }
      }

      .sign {
        @include rem(padding-top, 10px);
        @include rem(margin-top, 10px);
        background-image: linear-gradient(to right, $gray-darkest 33%, rgba(255,255,255,0) 0%);
        background-position: top;
        background-size: 5px 1px;
        background-repeat: repeat-x;

        a,
        strong {
          display: block;
          color: $black-lighter;
          text-decoration: none;
        }
      }
    }

    .region-footer {
      flex: 1;
      display: flex;
      justify-content: space-between;

      @include xssm {
        display: block;
      }

      .block {
        flex: 1;

        img {
          margin-left: -45px;
          margin-top: -40px;
          margin-bottom: 20px;

          @include xssm {
            margin: auto auto 40px;
            display: block;
          }
        }
      }

      .menu {
        margin: 0;
        padding: 0;

        @include xssm {
          @include rem(margin, 20px 0);
        }

        li {
          @include rem(margin-bottom, 10px);
          list-style: none;

          @include xssm {
            margin: 0;
            padding: 0;
          }

          a {
            text-transform: uppercase;
            color: $black;
            font-weight: bold;
          }
        }
      }
    }
  }
}
