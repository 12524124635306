@font-face {
		font-family: 'icofont';
		font-weight: normal;
		font-style: normal;
		src: url('../fonts/icofont.woff') format('woff'),
				 url('../fonts/icofont.woff2') format('woff2');
}

.icon[class^='icon-'],
.icon[class*=' icon-'] {
		display: inline-block;
		font-family: 'icofont';
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1;
		text-transform: none;
}

$icons: (
		arrow-left: '\EA01',
		arrow-right: '\EA02',
		cimetiere: '\EA03',
		entretien: '\EA04',
		facebook: '\EA05',
		fiche: '\EA06',
		formation: '\EA07',
		idea: '\EA08',
		maya: '\EA09',
		outil: '\EA0A',
		plante: '\EA0B',
		publication: '\EA0C',
		search: '\EA0D',
		espace-vert: '\EA0E',
		arrow-bottom: '\EA0F',
		fb: '\EA10',
		factory: '\EA11',
		cross: '\EA12',
		indigene: '\EA13',
		linkedin: '\EA14'
);

@each $name, $icon in $icons {
		.icon-#{$name}::before {
				content: $icon;
		}
}