.mobile-menu {
  display:none;
  width:40px;
  height:40px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 384;
  padding: 5px;

  @include xssm {
    display: block;
  }

  span {
    position:relative;
    display:block;
    margin: 18px 8px;

    &:after,
    &:before {
      content:"";
      position:absolute;
      left: 0;
    }
  }

  span,
  span:after,
  span:before {
    height:4px;
    width:25px;
    text-indent:-9999px;
    background-color:$black;
    transition:all .25s ease-out;
    border-radius: 3px;
  }

  span:before {
    top:-8px;
  }

  span:after {
    top:8px;
  }
}

.open {
  .mobile-menu{
    border:none;

    span:before,
    span:after{
      top:0;
    }

    span {
      background-color:rgba(0,0,0,0);

      &:after{
        -webkit-transform: translateY(0) rotate(-45deg);
        transform: translateY(0) rotate(-45deg);
      }

      &:before{
        -webkit-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
      }
    }
  }

  #header {
    transform: translateX(0%);
  }
}

#block-menu-menu-secondary-menu,
#block-system-main-menu {
  ul.menu {
    padding: 0;
    margin: 0;
    text-align: right;

    li {
      list-style: none;
      display: inline-block;

      @include xssm {
        display: block;
      }
    }
  }
}

#block-menu-menu-secondary-menu {
  @include rem(padding-bottom, 10px);
  background-image: linear-gradient(to right, $gray-darkest 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 5px 1px;
  background-repeat: repeat-x;

  @include xssm {
    background: $gray-lighter;
    border-bottom: 1px solid $gray;
    padding-bottom: 0;
  }

  a {
    color: $black;

    @include xssm {
      @include rem(padding, 10px 0);
      display: block;
      text-align: center;
    }
  }

  li {
    margin: 0 ;
    padding: 0;
    @include rem(margin-left, 30px);

    @include xssm {
      margin-left: 0;
    }
  }

  .icon-facebook {
    font-size: 0;
    vertical-align: baseline;

    &::before {
      @include rem(font-size, 15px);
      @include custom-font('\EA10');
      color: #3B5998;

      @include xssm {
        color: $white;
      }
    }

    @include xssm {
      background: #3B5998;
      color: $white;
    }
  }

  .icon-linkedin {
    font-size: 0;
    vertical-align: baseline;

    &::before {
      @include rem(font-size, 15px);
      @include custom-font('\EA14');
      color: #0c65c2;

      @include xssm {
        color: $white;
      }
    }

    @include xssm {
      background: #0c65c2;
      color: $white;
    }
  }
}

#block-system-main-menu {
  ul {
    li {
      margin: 0 ;
      padding: 0;
      @include rem(margin-left, 20px);

      @include xssm {
        margin-left: 0;
      }

      &.last {
        @include rem(margin-left, 10px);

        @include xssm {
          margin-left: 0;
        }
      }

      &.expanded {
        position: relative;

        > a {
          &:after {
            content:'\EA0F';
            font-family: icofont;
            font-size: 7px;
            margin-left: 12px;
            vertical-align: middle;
          }

          &.-is-open {
            + .menu {
              display: block;
            }
          }
        }

        .menu {
          text-align: center;
          display: none;

          @include mdlg {
            padding: 10px 20px;
            border: 1px solid $gray;
            background: $white;
            border-radius: 8px;
            box-shadow: 0 3px 10px rgba($black, 0.3);
            position: absolute;
            top: calc(100% + 12px);
            left: 50%;
            transform: translateX(-50%);
            width: 160px;
            z-index: 100;

            &:before {
              content:"";
              display: block;
              height: 12px;
              position: absolute;
              width: 100%;
              bottom: 100%;
              left: 0;
            }
  
            &:after {
              content:"";
              display: block;
              background: url('../images/arrow-top.svg') no-repeat;
              position: absolute;
              bottom: calc(100% - 1px);
              left: 50%;
              transform: translateX(-50%);
              width: 20px;
              height: 10px;
            }
          }

          li {
            display: block;
            margin: 0;
            border-bottom: 1px dashed $gray;
            line-height: 1;

            &:last-child {
              border: none;
            }
          }

          a {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
            display: block;
            padding: 10px 0;

            &:hover, 
            &:focus {
              color: $primary-color;
            }
          }
        }
      }

      a {
        @include rem(font-size, 18px);
        font-weight: 700;
        font-family: $headings-font-family;
        color: $black;

        @include xssm {
          @include rem(padding, 15px 0);
          border-bottom: 1px solid $gray;
          display: block;
          text-align: center;
        }

        &.icon-outils {
          @include rem(padding, 5px 20px);
          @include rem(font-size, 16px);
          background: $secondary-color;
          color: $white;
          border-radius: 40px;
          font-weight: normal;

          @include xssm {
            @include rem(padding, 15px 0);
            border-bottom: 1px solid $gray;
            display: block;
            text-align: center;
            border-radius: 0;
          }

          &:before {
            @include custom-font('\EA0A');
            @include rem(margin-right, 10px);
          }

          &:hover,
          &:focus,
          &.active {
            background: $secondary-color-darker;
          }
        }

        &.icon-plantes {
          @include rem(padding, 5px 20px);
          @include rem(font-size, 16px);
          background: $primary-color;
          color: $white;
          border-radius: 40px;
          font-weight: normal;

          @include xssm {
            @include rem(padding, 15px 0);
            border-bottom: 1px solid $gray;
            display: block;
            text-align: center;
            border-radius: 0;
          }

          &:before {
            @include custom-font('\EA0B');
            @include rem(margin-right, 10px);
          }

          &:hover,
          &:focus,
          &.active {
            background: $primary-color-darker;
          }
        }
      }
    }
  }
}

#block-multiblock-4 {
  .icon-facebook {
    font-size: 0;

    &::before {
      @include rem(font-size, 15px);
      @include custom-font('\EA10');
      color: #3B5998;
    }
  }

  .icon-linkedin {
    font-size: 0;
    vertical-align: top;

    &::before {
      @include rem(font-size, 15px);
      @include custom-font('\EA14');
      color: #0c65c2;
    }
  }
}

#block-multiblock-3 {
  .expanded {
    ul {
      li {
        margin: 0;
        margin-left: 20px
      }

      a {
        font-weight: normal;
        text-transform: none;
      }
    }
  }
}