header {
  @extend %container;
  @include rem(padding, 15px 0);

  @include xssm {
    position: relative;
  }
}

#header {

  @include xssm {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(100%);
    z-index: 450;
    background: $white;
    width: 80%;
    transition: all linear .2s;
  }

  .region-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include xssm {
      display: block;
      overflow-y: scroll;
    }
  }
}
