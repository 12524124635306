.node-home {
  max-width: $container-width + 200px;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .field-name-body {
    @include rem(margin, -20% auto 0);
    @include rem(padding, 40px 60px);
    @include rem(font-size, 21px);
    max-width: 70%;
    background: $white;
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    text-align: center;
    font-weight: 300;

    @include xssm {
      margin-top: 0;
      padding: 0;
      max-width: 90%;
    }

    .intro {
      font-weight: 400;
    }

    .btn {
      @include rem(margin, 15px 5px 0);

      @include xssm {
        @include rem(padding, 3px 15px);
      }
    }
  }
}

.view-agenda {

  > .view-header {
    .block-agenda {
      @include rem(padding, 20px 100px);
      @include rem(font-size, 21px);
      font-weight: 300;

      .intro {
        font-weight: 500;
      }
    }
  }

  .views-exposed-widgets {
    @include rem(margin-bottom, 50px);
    width: 300px;
    position: relative;

    .views-exposed-widget {
      float: none;

      > label {
        @include rem(font-size, $headings-3-font-size);
        @include rem(margin-bottom, 20px);
        width: 100%;
        font-weight: 400;
        color: $primary-color-darker;
        display: block;
        border-bottom: 1px solid $primary-color-darker;
      }
    }

    .views-submit-button {
      display: none;
    }

    .views-reset-button {
      position: absolute;
      top: 20px;
      right: 0;

      input {
        @include rem(font-size, 12px);
        margin: 0;
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
        color: $gray-darker;
        text-transform: uppercase;

        &:hover,
        &:focus {
          color: $primary-color;
        }
      }
    }
  }
}

.node-type-webform {

  .node-webform {
    max-width: 80%;
    margin: 0 auto;
    @include rem(margin-top, 50px);

    @include xssm {
     max-width: 100%;
    }
  }
}

.field-name-body {
  @include rem(font-size, 21px);
  font-weight: 300;
  color: $black-lighter;

  .intro {
    font-weight: 600;
  }

  .node-type-webform & {
    @include rem(margin-bottom, 50px);
    color: $black;
  }
}

.slick-prev{

  @include xssm {
    left: 0;
  }

  &:before {
    @include custom-font('\EA01');
    @include rem(font-size, 40px);
    color: $black;
  }
}

.slick-next {

  @include xssm {
    right: 0;
  }

  &:before {
    @include custom-font('\EA02');
    @include rem(font-size, 40px);
    color: $black;
  }
}

.views-field-field-image {
  .image {
    background: $primary-color-lighter;

    &:before {
      content: "";
      display: block;
      padding-top: 75%;
    }

    &:after {
      @include rem(font-size, 50px);
      position: absolute;
      color: $primary-color;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      text-align: center;
    }
  }

  .service-2 {
    .image {
      &:after {
        @include custom-font('\EA09');
      }
    }
  }

  .service-3 {
    .image {
      &:after {
        @include custom-font('\EA0E');
      }
    }
  }

  .service-4 {
    .image {
      &:after {
        @include custom-font('\EA03');
      }
    }
  }

  .service-18 {
    .image {
      &:after {
        @include rem(font-size, 60px);
        @include custom-font('\EA11');
      }
    }
  }
}

.field-name-field-pdf {
  &:before {
    @include rem(margin, 40px 0);
    display: block;
    content:"";
    background: $primary-color;
    height: 1px;
    width: 70px;
  }

  .field-label {
    @include rem(margin-bottom, 10px);
    font-weight: 600;
  }

  .field-items {
    display: flex;
    flex-wrap: wrap;

    .field-item {
      @include rem(margin-right, 10px);

      &:before {
        @include rem(margin-right, 5px);
        vertical-align: middle;
        content:"";
        display: inline-block;
        background: $black;
        width: 1px;
        height: 20px;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }

      img {
        display: none;
      }
    }
  }
}

.views-field-field-conseil {
  ul {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;

    @include xssm {
      display: block;
    }

    li {
      @include rem(margin, -150px 10px 0);
      @include rem(padding, 30px);
      flex: 1;
      list-style: none;
      background: $white;
      border: 1px solid $tertiary-color;
      box-shadow: 0 0 20px $gray;

      @include xssm {
        @include rem(margin-top, 30px);
      }

      .content {
        background: none;
      }

      .intro {
        @include rem(font-size, 18px);
        @include rem(margin-bottom, 50px);
        color: $tertiary-color;
      }

      .field-name-field-title {
        @include rem(font-size, 36px);
        @include rem(margin-bottom, 40px);
        font-weight: bold;
        color: $tertiary-color;

        &:after {
          display: block;
          content:"";
          width: 30px;
          height: 4px;
          background-color: $tertiary-color;
        }
      }
    }
  }
}

.views-field-field-images,
.views-field-field-image {
  img {
    width: 100%;
  }
}

.field-name-indigene {
  display: inline-block;
  margin-left: 10px;
  font-size: 45px;
  vertical-align: middle;

  p {
    margin: 0;
  }
}

.group-titre {
  .field-name-title {
    display: inline-block;
  }
}
