$enable-legacy-support-for-ie8: true;
$container-width:         1240px;
/* ------------------------
 * COLORS
 * -----------------------*/

 $gray-lighter:            #F7F7F7;
 $gray:                    #D5D5D5;
 $gray-darker:             #C5C5C5;
 $gray-darkest:            #979797;

 $red:                     #AC232B;

 $white:                   #fff;

 $black-lighter:           #7B7B7B;
 $black:                   #454545;

 $primary-color-lighter:   #F4F4EA;
 $primary-color:           #848735;
 $primary-color-darker:    #59591E;

 $secondary-color-lighter: #FFF8EE;
 $secondary-color:         #F7A600;
 $secondary-color-darker:  #A36800;

 $tertiary-color:          #9E8C7A;

 $warning-color:           #fec557;

/* ------------------------
 * FONTS
 * -----------------------*/
@import url("https://use.typekit.net/mee4nmm.css");

$global-font-size:        16px;
$global-line-height:      1.8;
$global-font-family:      usual, sans-serif;

// Headings
$headings-1-font-size:      54px;
$headings-2-font-size:      48px;
$headings-3-font-size:      30px;
$headings-4-font-size:      18px;
$headings-5-font-size:      16px;
$headings-6-font-size:      16px;

$headings-color:            $primary-color-darker;
$headings-font-family:      ratio, sans-serif;
$headings-font-weight:      400;
$headings-margins:          0 0 30px 0;

/* -----------------------
 * Mediaqueries
 * ---------------------*/

 //== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-xxs: 320px;
// Extra small screen / phone
// Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
// Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
// Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
// Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 700px;
$screen-sm-min: $screen-sm;
// Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
// Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
// Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
// Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
// Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-xlg-min: 1781px;
