.block-views {
  h2 {
    color: $secondary-color;
    font-family: $global-font-family;
    font-weight: 300;
    text-align: center;

    @include xssm {
      @include rem(margin-bottom, 15px);
    }

    &:after {
      @include rem(margin, 10px auto 30px);
      display: block;
      height: 1px;
      width: 60px;
      background: $secondary-color;
      content: "";
    }
  }
}

#block-views-services-block {
  @include rem(padding, 100px 0);
  text-align: center;

  .views-field-field-image {
    @include rem(margin, 0 auto 30px);
    width: 70%;

    a {
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
    }

    img {
      display: block;
    }
  }

  h3 {
    a {
      color: $primary-color-darker;
    }
  }

  .views-field-body {
    color: $black-lighter;
  }

  .views-field-path {
    @include rem(margin-top, 10px);
  }

  .views-row {
    @include rem(padding, 0 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;

    .views-field-body {
      flex: 1;
    }
  }

  .slick-track {
    display: flex;
  }
}

#block-views-contact-block,
#block-multiblock-5 {
  @include rem(padding, 30px);
  @include rem(margin-top, 80px);
  border: 1px solid $secondary-color;
  box-shadow: 0 0 20px lighten($gray, 2%);

  h2 {
    @include rem(font-size, 36px);
    @include rem(margin-bottom, 30px);
    text-align: left;
    font-weight: 700;
    line-height: 1;

    &:after {
      width: 30px;
      height: 3px;
      margin-left: 0;
    }
  }

  .location {
    .fn {
      @include rem(margin-bottom, 15px);
      font-weight: 700;
      display: block;
    }

    .email {
      @include rem(margin-top, 15px);
    }

    a {
      color: $black-lighter;

      &:hover,
      &:focus {
        color: $primary-color;
      }
    }
  }

  .view-equipe {
    .image {
      border-radius: 50%;
      position: relative;
      margin-top: 30px;
      max-width: 80px;

      &::before {
        padding-top: 100%;
      }

      &:after {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .service-2 {
      .image {
        &:after {
          @include custom-font('\EA09');
        }
      }
    }
  
    .service-3 {
      .image {
        &:after {
          @include custom-font('\EA0E');
        }
      }
    }
  
    .service-4 {
      .image {
        &:after {
          @include custom-font('\EA03');
        }
      }
    }
  
    .service-18 {
      .image {
        &:after {
          @include rem(font-size, 60px);
          @include custom-font('\EA11');
        }
      }
    }
  }
}

#block-views-maps-block {
  > h2 {
    @extend %container;
    max-width: calc(90% - 200px);
    text-align: left;
    margin-bottom: 0;

    @include xssm {
      max-width: 90%;
    }

    &:after {
      margin-left: 0;
    }
  }

  .content {
    .view-header {
      @include rem(margin, 0 auto 60px);
      max-width: calc(90% - 200px);

      @include xssm {
        max-width: 90%;
      }

      p {
        max-width: 60%;
      }
    }
  }
}

#block-views-home-block {
  .views-field-field-video {
    img {
      width: 100%;
    }
  }

  .views-field-title {
    position: absolute;
    top: 230px;
    left: 0;
    z-index: 15;
    text-align: center;
    width: 100%;

    @include xssm {
      position: relative;
      top: auto;
    }

    h1 {
      @include rem(font-size, 50px);
      font-family: $global-font-family;
      font-weight: 700;
      color: $white;
      max-width: 40%;
      margin-left: auto;
      margin-right: auto;
      line-height: 1;
      text-shadow: 1px 1px 5px $black;

      @include xssm {
        text-shadow: none;
        color: $black;
        @include rem(font-size, 35px);
        max-width: 90%;
      }

      &:after {
        display: none;
      }
    }
  }
}

#block-views-equipe-block {
  @include rem(margin, 100px auto 0);
  max-width: 780px;

  .view-content {
    display: flex;
    flex-wrap: wrap;

    .views-row {
      flex: 0 0 33.333%;
      position: relative;
      overflow: hidden;

      @include sm {
        flex: 0 0 50%;
      }

      @include xs {
        flex: 0 0 100%;
      }

      .views-field-field-image {
        .image {
          &:before {
            padding-top: 100%;
          }
        }
      }

      &:hover,
      &:focus {

        .views-field-field-image {
          opacity: 0.7;

          &:after {
            opacity: 1;
          }
        }

        .views-field-title {
          top: 50%;
          opacity: 1;
        }
      }
    }

    .views-field-field-image {
      transition: all 0.4s ease-in-out 0s;

      img {
        display: block;
      }

      &:after {
        background: rgba($secondary-color, .7);
        display: block;
        content:"";
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        transition: all 0.4s ease-in-out 0s;
      }
    }

    .views-field-title {
      position: absolute;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      width: 100%;
      top: 80%;
      left: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out 0s;

      h3 {
        @include rem(margin-bottom, 10px);
        color: $secondary-color-darker;
        text-transform: uppercase;
        font-weight: bold;

        &:after {
          background-color: $secondary-color-darker;
          display: block;
          content:"";
          height: 4px;
          width: 30px;
          margin: 5px auto;
        }
      }

      a {
        color: $secondary-color-darker;
        text-decoration: none;
      }

      p {
        color: $black;
      }
    }
  }
}

#block-views-services-block-2 {
  @include rem(margin-top, -100px);
  @include rem(padding, 30px);
  background: $white;
  border: 1px solid $secondary-color;
  box-shadow: 0 0 20px $gray;

  @include xssm {
    @include rem(margin-top, 30px);
  }

  h2 {
    @include rem(font-size, 36px);
    @include rem(margin-bottom, 50px);
    text-align: left;
    font-weight: 700;

    &:after {
      @include rem(margin-top, 10px);
      margin-left: 0;
      height: 5px;
      width: 40px;
    }
  }

  .views-field-field-image {
    border-radius: 50%;
    overflow: hidden;
    width: 50%;
    position: relative;

    img {
      display: block;
    }

    .image {
      &:before {
        padding-top: 100%;
      }
    }
  }

  h3 {
    @include rem(font-size, 23px);
    @include rem(margin-top, 30px);
    font-family: $headings-font-family;
    color: $black-lighter;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
  }

  .views-field-field-fonction {
    @include rem(margin, 20px 0);
  }
}

#wide-content {
  @include rem(margin-top, 100px);
}


#block-views-texte-block {

  .page-node-13 & {
    @extend %container;
    @include rem(padding-right, 150px);
    box-sizing: border-box;
    max-width: 800px;

    @include xssm {
      padding-right: 0;
    }

    h3 {
      @include rem(margin-top, 100px);
    }
  }

  .node-type-service & {
    @include rem(margin-top, 100px);

    .views-field-field-paragraphes {
      max-width: 1240px;
      margin: 0 auto;
    }

    .entity-paragraphs-item {
      @include rem(margin-bottom, 30px);
      position: relative;

      .content {
        display: flex;
        align-items: center;

        @include xssm {
          display: block;
          max-width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .field-name-field-image {
        flex: 0 0 35%;

        img {
          width: 100%;
        }
      }

      .field-name-field-body {
        line-height: 1.8;

        h2 {
          @include rem(font-size, 36px);
          color: $primary-color-darker;
          text-align: left;

          @include xssm {
            @include rem(font-size, 26px);
            @include rem(margin-top, 20px);
          }

          &:after {
            margin-left: 0;
            background: $primary-color-darker;
          }
        }
      }

      &.paragraphs-item-image-texte {
        margin-right: auto;
        @include lg {
          margin-left: -100px;
        }

        .field-name-field-image {
          @include rem(margin-right, 40px);

          @include xssm {
            margin-right: 0;
          }
        }

        .field-name-field-body {
          @include lg {
            padding-right: 200px;
          }

        }
      }

      &.paragraphs-item-texte-image {
        margin-left: auto;
        @include lg {
          margin-right: -100px;
        }

        .field-name-field-image {
          @include rem(margin-left, 40px);

          @include xssm {
            margin-left: 0;
          }
        }

        .field-name-field-body {
          @include lg {
            padding-left: 200px;
          }
        }
      }
    }
  }
}


.block-views-agenda-block_3-instance,
#block-views-agenda-block-3 {
  margin-left: calc(28% + 140px);

  @include xssm {
    margin-left: 0;
  }

  .content {
    .page-node-2 #sub-content & {
      padding-bottom: 0;
    }
  }

  .view-agenda {
    width: 100%;
  }

  h2 {
    @include rem(font-size, 28px);
    color: $secondary-color;
    font-family: $global-font-family;
    max-width: 300px;
    text-align: left;

    &:after {
      background: $secondary-color;
      margin-left: 0;
    }
  }

  .view-content {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -10px;
    margin-right: -10px;

    @include xssm {
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    .views-row {
      flex: 0 1 50%;
      margin: 0 10px;
      background: $white;
      @include rem(padding-bottom, 20px);
      border: 1px solid $gray;
      box-sizing: border-box;

      @include xssm {
        @include rem(margin, 0 0 20px);
      }

      h3 {
        @include rem(font-size, 24px);
        a {
          color: $secondary-color;
        }
      }

      h3 {
        @include rem(font-size, 26px);
        @include rem(padding-bottom, 10px);
        @include rem(margin-bottom, 5px);
        border-bottom: 1px dashed $gray;
        a {
          color: $secondary-color;
          font-weight: 600;
        }
      }

      .views-field-field-image {
        @include rem(margin-bottom, 20px);
        position: relative;

        .image {
          &:before {
            display: block;
            content:"";
            padding-top: 75%;
          }
        }

        img {
          display: block;
        }

        .service-1,
        .service-2,
        .service-3,
        .service-4 {
          background: $primary-color-lighter;
        }
      }

      .views-field-title,
      .views-field-field-service,
      .views-field-field-date,
      .views-field-field-date-de-fin,
      .views-field-field-organisateur,
      .views-field-body,
      .views-field-path {
        @include rem(padding, 0 20px);
      }

      .views-field-field-service {
        a {
          @include rem(font-size, 14px);
          color: $gray-darker;
        }
      }

      .views-field-field-date,
      .views-field-field-date-de-fin,
      .views-field-field-organisateur {
        @include rem(font-size, 14px);
        font-weight: 600;
        color: $black-lighter;
      }

      .views-field-body {
        color: $black-lighter;
      }

      .views-field-path {
        a {
          color: $primary-color;
          font-weight: 600;
          border-bottom: 1px solid $primary-color;
          text-decoration: none;
        }
      }
    }
  }

  .view-footer {
    @include rem(margin, 60px 0);
    text-align: center;
  }
}

#block-views-partner-block {
  @extend %container;

  > .content {
    position: relative;
  }

  .view-content {
    margin-left: -1%;
    margin-right: -1%;

    .views-row {
      border: 1px solid #D5D5D5;
      box-sizing: border-box;
    }
  }

  .views-field-field-image {
    @include rem(padding, 15px 0 0);
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    .field-content {
      max-width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: block;
        max-height: 100%;
      }

      img {
        display: block;
        max-height: 100%;
        width: auto;
      }
    }
  }

  .views-field-title {
    @include rem(padding, 20px);

    .field-content {
      @include rem(padding-top, 15px);
      display: block;
      background-image: linear-gradient(to right, $gray-darkest 33%, rgba(255,255,255,0) 0%);
      background-position: top;
      background-size: 5px 1px;
      background-repeat: repeat-x;
    }

    .texte {
      @include rem(padding-bottom, 15px);
      @include rem(margin-bottom, 20px);
      background-image: linear-gradient(to right, $gray-darkest 33%, rgba(255,255,255,0) 0%);
      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;

      h3 {
        @include rem(font-size, 24px);

        a {
          color: $secondary-color;
        }
      }

      p {
        color: $gray-darker;
        line-height: 1.2;
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }
    }
  }

  .btn {
    @include rem(padding, 0px 15px);
    color: $primary-color;
    border-color: $primary-color;
    border-width: 1px;
    font-weight: 600;

    &:hover,
    &:focus {
      background: $primary-color;
      color: $white;
    }
  }
}

.block-nav {
  @include rem(margin-top, 50px);

  h4 {
    @include rem(font-size, 13px);
    @include rem(margin-bottom, 20px);
    color: $gray-darker;
    text-transform: uppercase;
    font-weight: 400;
    font-family: $global-font-family;

    &:after {
      @include rem(margin, 10px 0);
      content:"";
      display: block;
      height:1px;
      width: 30px;
      background: $gray-darker;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 0;
      list-style: none;

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:after {
        @include rem(margin, 0 10px);
        content:"";
        display: inline-block;
        height: 17px;
        width: 1px;
        background: $gray-darker;
        vertical-align: middle;
      }

      a {
        text-decoration: none;

        br {
          display: none;
        }
      }
    }
  }
}

#four-content {
  #block-views-projet-block-1 {
    @include rem(padding, 50px 0);
    background: $secondary-color-lighter;

    h2 {
      @include rem(font-size, 36px);
      color: $secondary-color;
      text-align: left;
      font-family: $headings-font-family;

      &:after {
        background: $secondary-color;
        margin-left: 0;
      }
    }

    h2,
    .content {
      @extend %container;
    }

    .view-projet {
      > .view-header {
        @include rem(font-size, 18px);
        @include rem(padding, 30px 0 50px);
        color: $secondary-color;
        max-width: 60%;

        @include xssm {
          max-width: 100%;
        }
      }

      > .view-content {
        margin-left: -1%;
        margin-right: -1%;
        overflow: hidden;
        display: grid;
        grid-gap: 32px;

        @include lg {
          grid-template-areas: '. . . .';
        }

        @include md {
          grid-template-areas: '. . .';
        }

        @include sm {
          grid-template-areas: '. .';
        }

        @include xs {
          display: block;
          margin-left: 0;
          margin-right: 0;
        }

        .views-row {
          background: $white;
          box-sizing: border-box;
          border: 1px solid $gray;
          break-inside: avoid-column;

          @include xs {
            margin-bottom: 32px;
            width: 100%;
          }
        }

        .views-field-field-service,
        .views-field-title {
          @include rem(margin, 0 10px);
        }

        .views-field-title {
          @include rem(padding-bottom, 10px);
          background-image: linear-gradient(to right, $gray-darkest 33%, rgba(255,255,255,0) 0%);
          background-position: bottom;
          background-size: 5px 1px;
          background-repeat: repeat-x;

          h3 {
            @include rem(font-size, 26px);
            margin: 0;
          }
        }

        .views-field-field-service {
          @include rem(padding, 10px 0);
        }
      }
    }
  }
}
